<template>
  <div class='home'>
    <el-row :gutter="15">
      <el-col :span="24/(loginType=='merchantLogin'?cards:cuCards).length" v-for="(t, i) in loginType=='merchantLogin'?cards:cuCards" :key="i">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>{{$t(t.title)}}</span>
            <el-tag v-if="t.tagShow" style="float: right; height:18px;line-height:18px">{{$t("today")}}</el-tag>
          </div>
          <div v-if="t.isMoney" class="exhibition">
            <span class="franc">₣</span>{{t.content.formatMoney(0)}}
          </div>
          <div v-else class="exhibition"> {{t.content}} </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="15" class="residual-space">
      <el-col :span="16" style="height:100%">
        <el-card class="box-card" shadow="never" :body-style="{padding:'15px 0',height:'100%',overflow:'hidden'}">
          <div slot="header" class="clearfix">
            <span>{{$t('recent trading record')}}</span>
          </div>
          <div class="table-is-page" :style="{height:`calc(100% - 70px)`}">
            <el-table :data="transactionRecord" style="width:100%" size="mini" height="100%">
              <el-table-column prop="createTime" :label="$t('tradingTime')" width="160">
                <template slot-scope="scope">
                  {{$manba(scope.row.createTime).format('ymdhms')}}
                </template>
              </el-table-column>
              <el-table-column prop="businessName" :label="$t('dealType')" min-width="120">
                <template slot-scope="scope">
                  {{$t(scope.row.businessName)}}
                </template>
              </el-table-column>
              <el-table-column prop="transactionCode" :label="$t('Transaction no.')" min-width="120"> </el-table-column>
              <el-table-column prop="amount" :label="$t('transactionAmount')" min-width="140">
                <template slot-scope="scope">
                  <span class="franc">₣</span> {{scope.row.amount?scope.row.amount.formatMoney(0):0}}
                </template>
              </el-table-column>
              <el-table-column prop="createRemark||remark" :label="$t('explain')" min-width="200">
                <template slot-scope="scope">
                  <div class="overstep-hidden">
                    <translation v-if="scope.row.createRemark" :text="scope.row.createRemark" />
                    <translation v-if="scope.row.remark" :text="scope.row.remark" />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
      <!-- 通知 -->
      <el-col :span="8" style="height:100%">
        <el-card class="box-card" shadow="never" :body-style="{padding:'0',height:'calc(100% - 56px)'}">
          <div slot="header" class="clearfix">
            <span>{{$t('notification')}}</span>
          </div>
          <div class="infinite-list" style="height:100%;overflow:auto" v-infinite-scroll="loadNotifications" :infinite-scroll-distance="100">
            <ul class="notification-list">
              <li v-for="(t,i) in notifications.list" :key="i" class="notification-item" @click="increase(t)">
                <div class="item-left">
                  <div class="item-title overstep-hidden1">{{t.title}}</div>
                  <div class="item-content overstep-hidden1">{{t.content}}</div>
                </div>
                <div class="item-right">
                  <div class="item-datetime">{{$manba(t.createTime).format('ymdhms')}}</div>
                  <el-tooltip class="item" effect="dark" :content="$t('clickNumber')" placement="top-end">
                    <span><i class="iconfont icondianji"></i> {{t.browseNum||0}}</span>
                  </el-tooltip>
                </div>
              </li>
            </ul>
            <p v-if="notifications.loading" style="text-align: center;color: #aaa;">{{$t('loading')}}</p>
            <p v-if="notifications.noMore" style="text-align: center;color: #aaa;">{{$t('noMore')}}</p>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog :title="notifications.dialog.title" :visible.sync="notifications.dialog.visible" width="600px" top="20vh" destroy-on-close :modal-append-to-body="false" center custom-class="custom-dialog">
      <p>{{notifications.dialog.content}}</p>

      <span slot="footer" class="dialog-footer">
        <el-button @click="notifications.dialog.visible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import translation from "../../components/specialTranslation.vue"


import message from '@/api/message'
import card from '@/api/card'
import home from '@/api/home'
import customerBusiness from '@/api/customerBusiness'
import transactionInfo from '@/api/transactionInfo'

import storage from '@/assets/uilt/storage';

export default {
  // name: "home",
  components: { translation },
  data () {
    return {
      cards: [{ title: "cardNumber", content: '--', },
      { title: "accountAmount", content: 0, isMoney: true },
      { title: "cashAmount", content: 0, isMoney: true },
      { title: "businessStatistics", content: 0, }],

      cuCards: [{ title: "cardNumber", content: '--', },
      { title: "accountAmount", content: 0, isMoney: true },
      { title: "businessStatistics", content: 0 }],

      notifications: {
        list: [],
        noMore: false,
        loading: false,
        current: 0,
        dialog: {
          visible: false,
          title: "",
          content: ""
        }
      },

      loginType: "merchantLogin",

      /**30条最近交易记录 */
      transactionRecord: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    /**机构账户登录查询交易记录 */
    getTransactionRecord () {
      let _this = this;
      transactionInfo.getPage({
        param: { size: 30 },
        success: function (res) {
          _this.transactionRecord = res.data ? res.data.records : [];
        }
      })
    },
    /**个人账户登录查询交易记录 */
    cuGetTransactionRecord () {
      let _this = this;
      customerBusiness.listCardFlow({
        param: { size: 30 },
        success: function (res) {
          _this.transactionRecord = res.data ? res.data.records : [];
        }
      })
    },
    /**查询通知 */
    getNotifications () {
      let _this = this;
      _this.notifications.loading = true;
      let pageSize = 3
      message.getPage({
        param: { current: _this.notifications.current, size: pageSize, status: 0 },
        success: res => {
          if (res.code == 200)
            _this.notifications.list.push(...res.data.records);

          if (!res.data || res.data.length < pageSize)
            _this.notifications.noMore = true;

          _this.notifications.loading = false;
        }
      })
    },
    loadNotifications () {
      if (this.notifications.noMore || this.notifications.loading) return;
      this.notifications.current++;
      this.getNotifications();
    },
    /**增加浏览次数 */
    increase (row) {
      this.notifications.dialog = { visible: true, ...row }

      message.browseMessageById({
        param: { id: row.id },
        success: res => {
          if (res.code == 200)
            row.browseNum++;
        }
      })
    },
    /**个账户以外的信息查询 */
    getHeaderData () {
      card.getCardAccount({
        success: res => {
          if (res.code == 200) {
            this.cards = [{ title: "cardNumber", content: res.data.cardNo || '--', },
            { title: "accountAmount", content: res.data.accountAmount || 0, isMoney: true },
            { title: "cashAmount", content: res.data.cashAmount || 0, isMoney: true },
            { title: "businessStatistics", content: 0, tagShow: true }]
            this.$store.dispatch('globalParameter/setCardInfo', JSON.parse(JSON.stringify(res.data)));
          }

          home.countToday({
            success: res2 => {
              let bs = this.cards.find(function (t) { return t.title == "businessStatistics" });
              bs.content = res2.data || 0;

              this.$store.dispatch('globalParameter/setCardInfo', JSON.parse(JSON.stringify(res.data)));
            }
          })

        }
      })
    },
    /**客户(个人) 查询 */
    getCustomerHeaderData () {
      let _this = this;
      customerBusiness.getCardAccount({
        success: res => {
          if (res.code == 200) {
            let cards = [{ title: "cardNumber", content: res.data.cardNo || '--', },
            { title: "accountAmount", content: res.data.accountAmount || 0, isMoney: true },
            { title: "businessStatistics", content: 0, tagShow: true }];

            customerBusiness.countToday({
              success: res2 => {
                let bs = _this.cuCards.find(function (t) { return t.title == "businessStatistics" });
                bs.content = res2.data || 0;

                _this.$set(_this, 'cuCards', cards)
                _this.$store.dispatch('globalParameter/setCardInfo', JSON.parse(JSON.stringify(res.data)));
              }
            })
          }
        }
      })
    }
  },
  mounted () {
    let loginType = storage.local.get('loginName');
    /**缓存里没有登录类型跳转到机构登录页面 */
    if (!loginType) {
      this.$router.push({ name: 'merchantLogin' })
    }
    else if (loginType.name === 'merchantLogin') {
      this.getHeaderData();
      this.getTransactionRecord();
      this.loginType = loginType.name;
    }
    else if (loginType.name === 'customerLogin') {
      this.getCustomerHeaderData();
      this.cuGetTransactionRecord();
      this.loginType = loginType.name;
    }

    this.getTransactionRecord();
  }
}
</script>
<style  lang='less'>
.home {
  i {
    font-size: 14px;
  }
  .residual-space {
    margin-top: 15px;
    position: absolute;
    top: 155px;
    bottom: 0;
    left: 0;
    right: 0;
    .el-col {
      height: 100%;
    }
    .el-card {
      height: 100%;
    }
  }
  .custom-dialog {
    color: red;
    .el-dialog__body {
      padding: 0 20px;
      p {
        letter-spacing: 1.5px;
        line-height: 24px;
      }
    }
  }
  .exhibition {
    font-size: 20px;
    font-weight: bold;
    line-height: 65px;
    text-align: right;
    .franc {
      font-size: 28px;
      font-weight: 100;
    }
  }
  .infinite-list {
    .notification-list {
      // margin: 0 -10px;
      .notification-item {
        height: auto;
        line-height: 28px;
        padding: 5px 15px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        .item-left {
          margin-right: 140px;
        }
        .item-right {
          position: absolute;
          top: 5px;
          right: 15px;
          text-align: right;
        }
        .item-left .item-content,
        .item-right .item-datetime {
          color: @info;
          font-size: 12px;
        }
      }
      .notification-item:hover {
        background-color: @french_grey;
      }
      .overstep-hidden1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
